<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />

  <!-- ======= Hero Section ======= -->
  <hero-bg
    title="Web APPLICATIONS Development"
    content="Get scalable, reliable, fast, and efficient custom web applications that meet your business needs."
    url="#"
    image="web-app.png"
    button="GET SERVICE"
  />

  <main id="main">
    <solution-content
      content="We use dynamic and agile web-based applications development methods to achieve scalable, reliable, fast, and efficient custom web applications that meet our clients’ business needs. Our web applications are responsive and can be optimally viewed in various screen sizes like desktop computers, laptops, tablets, and mobile phones."
    />
  </main>

  <!-- footer -->
  <Footer />
  </div>

</template>

<script>
  import TopBar from "./TopBar";
  import Header from "./Header";
  import HeroBg from "./HeroBg";
  import SolutionContent from "./SolutionContent";

  import Footer from "./Footer";
export default {
  name: 'web-app-dev',
  components: {
    TopBar,
    Header,
    HeroBg,
    SolutionContent,
    Footer
  }
}
</script>

<style scoped></style>
