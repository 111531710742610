<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />
  <main id="main">
    <solution-header
      name="Kisumu County Mapping App"
      client="Kisumu County, subcontracted by TeraTrigg"
      type="Mobile"
    />
  </main>
  <div class="container">
    <div class="row step my-5">
      <div class="col-md-5">

      </div>
      <div class="col-md-7">
        <p class="none-list mt-lg-2">
          The Kisumu County App is a GIS mobile application used for mapping
          land features. It is used to capture coordinates, photos, videos, and
          description of the features being mapped and submit data to an online
          ArcGIS server. It can be used to map landmark features such as rivers,
          mountains, buildings etc. The following are some of the
          functionalities of the app:
        </p>
        <div
          v-for="list in lists.slice(0, 5)"
          :key="list"
          class="row py-2 mx-lg-3"
        >
          <div class="col-1">
            <i class="bi bi-check-circle-fill"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
        <div
          v-for="list in lists.slice(6)"
          :key="list"
          class="row py-2 mx-lg-3"
        >
          <div class="col-1">
            <i class="bi bi-check-circle-fill"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
  import TopBar from "./TopBar";
  import Header from "./Header";
  import Footer from "./Footer";
  import SolutionHeader from "./SolutionHeader";
export default {
  name: 'kisumu-map-app',
  components: {
    TopBar,
    Header,
    Footer,
    SolutionHeader
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      lists: [
        'Loads and show the map of the current location. ',
        'Capture coordinates of your location. ',
        'Take a photo of the location. ',
        'Take video of the location. ',
        'Capture description of the feature. ',
        'Save the captured details.',
        'View, edit and delete the saved data. ',
        'Send the captured details to an online ArcGIS server. ',
        'Save data offline and synchronize automatically in the background when device connects to internet.'
      ]
    }
  }
}
</script>

<style scoped>
body {
  background-color: #ffffff !important;
}
i {
  color: #00a7ea;
}
.list {
  color: #000000;
  font: normal normal normal 1em Rubik;
}
.col-1 {
  width: 3% !important;
}
.none-list {
  font: normal normal normal 1em/45px Rubik;
  color: #000000;
  opacity: 1;
}
iframe {
  overflow: hidden;
}
</style>
