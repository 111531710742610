<template>
  <section class="section mt-5">
    <div class="container">
      <div class="row">
        <h1 class="text-center">{{ name }}</h1>
        <div class="row d-flex justify-content-center">
          <div class="col-auto">
            <span class="mx-4" style="color: #0864B1">Client:</span
            ><span style="color: #7A7A7A">{{ client }}</span>
          </div>
          <div class="col-auto">
            <span class="mx-4" style="color: #0864B1">Type:</span
            ><span style="color: #7A7A7A">{{ type }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SolutionHeader',
  props: {
    name: String,
    client: String,
    type: String
  }
}
</script>

<style scoped>
h1 {
  color: #0864b1;
  text-transform: uppercase;
  opacity: 1;
}
</style>
