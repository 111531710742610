<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />
  <main id="main">
    <solution-header name="Sqwodi" client="Sqwodi" type="Mobile" />
  </main>
  <div class="container">
    <div class="row step my-5">
      <div class="col-md-5">
        <img
          :src="`${publicPath}assets/img/portfolio/sqwodi.jpg`"
          class="img-fluid"
        />
      </div>
      <div class="col-md-7">
        <p class="none-list mt-lg-2">
          Sqwodi is a mobile application that helps unskilled workers to get
          access to jobs to a wide range of clients and helps a user get the
          right service providers at the comfort of their comforts. It is also
          used to achieve accountability by avoiding, reducing, and resolving
          broken agreements e.g., changed costs of goods and services – a
          service person cannot change the price without asking the client to
          agree. It is also used to effectively match clients and service
          provider using the ratings. It provides the following functionalities:
        </p>
        <div v-for="list in lists" :key="list" class="row py-2 mx-lg-3">
          <div class="col-1">
            <i class="bi bi-check-circle-fill"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
      </div>
      <!--<div class="col-12 mt-lg-4">

      </div>-->
    </div>
  </div>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
  import TopBar from "./TopBar";
  import Header from "./Header";
  import Footer from "./Footer";
  import SolutionHeader from "./SolutionHeader";
export default {
  name: 'sqwodi',
  components: {
    TopBar,
    Header,
    Footer,
    SolutionHeader
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      lists: [
        'System suggests good service providers based on customers need. ',
        'A customer can request for a service by choosing a category, providing description, and adding photos. ',
        'A service provider receives service requests and send quotes to bid. ',
        'Customer can then a provider of their choice based on their quality of service and quotation. ',
        'Customers make payments through MPESA or cards. ',
        'The payment is protected (by escrow) and is only released when both parties are satisfied with then work. ',
        'Service providers update their progress. ',
        'Customer approve works done. ',
        'Disputes can be easily resolved on the platform.'
      ]
    }
  }
}
</script>

<style scoped>
body {
  background-color: #ffffff !important;
}
i {
  color: #00a7ea;
}
.list {
  color: #000000;
  font: normal normal normal 1em Rubik;
}
.col-1 {
  width: 3% !important;
}
.none-list {
  font: normal normal normal 1em/45px Rubik;
  color: #000000;
  opacity: 1;
}
</style>
