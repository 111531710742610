<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />

  <!-- ======= Hero Section ======= -->
  <hero-bg
    title="SMS/VAS SYSTEM"
    content="Reach your customers in a click of a button using our robust bulk messaging platform."
    url="#"
    image="USSD.png"
    button="Buy Now"
  />

  <main id="main">
    <section class="section bg-white pb-0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="soln-text">
              Our robust bulk messaging platform guarantees fast, and 100%
              delivery of all messages sent, provide detailed and comprehensive
              reports and analytics to help users make informed decisions. The
              platform can be accessed using any device including desktop,
              laptop, or smartphones and offers the following features:
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section pt-0">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div v-for="list in lists.slice(0, 5)" :key="list" class="row py-2">
              <div class="col-1">
                <i class="bi bi-lightning-fill"></i>
              </div>
              <div class="col-11 list">
                {{ list }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              v-for="list in lists.slice(6, 10)"
              :key="list"
              class="row py-2"
            >
              <div class="col-1">
                <i class="bi bi-lightning-fill"></i>
              </div>
              <div class="col-11 list">
                {{ list }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="row py-4">
      <img :src="base_url + 'assets/img/services/features.png'" />
    </div>
    <section class="section">
      <div class="container">
        <div class="row d-flex justify-content-center mt-3">
          <div class="col-auto d-flex align-items-center">
            <span class="ready px-4">ready?</span>
            <router-link to="/">
              <button class="btn btn-lg btn-ready">GET SOLUTION</button>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </main>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
import TopBar from "./TopBar";
import Header from "./Header";
import HeroBg from "./HeroBg";

import Footer from "./Footer";
export default {
  name: 'vas-system',
  components: {
    TopBar,
    Header,
    HeroBg,
    Footer
  },
  data() {
    return {
      base_url: this.$store.state.base_url,
      lists: [
        'Easy to integrate API endpoint. ',
        'Personalized messages templates to suit your target recipient. ',
        'Comprehensive and detailed reports ',
        'Import phone numbers from Excel or CSV files ',
        'Create contact groups',
        'Schedule messages ',
        'Send messages to unlimited contacts. ',
        'Send a message to contact groups or selected contacts. ',
        'Top up your account using mobile payments and cards. ',
        'View detailed reports of messages sent, credit used.'
      ]
    }
  }
}
</script>

<style scoped>
.app-text {
  text-align: center;
  font: normal normal bold 20px/30px Roboto;
  color: #0864b1;
  text-transform: uppercase;
  opacity: 1;
}
i {
  color: #0864b1;
}
.list {
  color: #000000;
  font: normal normal normal 1em Rubik;
}
.col-1 {
  width: 3% !important;
}
.ready {
  text-align: center;
  font: normal normal normal 2em/32px Rubik;
  letter-spacing: 1.63px;
  color: #17479d;
  text-transform: uppercase;
  opacity: 1;
}
.btn-ready {
  background: #17479d 0% 0% no-repeat padding-box;
  border-radius: 42px;
  text-align: center;
  font: normal normal normal 2em/25px Rubik;
  letter-spacing: 1.63px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}
</style>
