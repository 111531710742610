<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />

  <!-- ======= Hero Section ======= -->
  <hero-bg
    title="ui/ux design & development"
    content="We develop systems that not only catch the eye but provide an indispensable user experience."
    url="#"
    image="uiux.png"
    button="GET SERVICE"
  />

  <main id="main">
    <solution-content
      content="We provide top notch UI/UX development that have eye catching look and feel and provide an indispensable user experience that goes beyond visually appealing screens. Our designs allow seamless navigation, quick information availability, and enhanced functionality."
    />
  </main>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
  import TopBar from "./TopBar";
  import Header from "./Header";
  import HeroBg from "./HeroBg";
  import SolutionContent from "./SolutionContent";

  import Footer from "./Footer";
export default {
  name: 'ui-design-dev',
  components: {
    TopBar,
    Header,
    HeroBg,
    SolutionContent,
    Footer
  }
}
</script>

<style scoped></style>
