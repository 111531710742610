<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />
  <main id="main">
    <solution-header name="Abudu" client="Aman Limited" type="Mobile" />
  </main>
  <div class="container">
    <div class="row step my-5">
      <div class="col-md-5">
        <img
          :src="`${publicPath}assets/img/portfolio/abudu-ke.jpg`"
          class="img-fluid"
        />
      </div>
      <div class="col-md-7">
        <p class="none-list mt-lg-2">
          Abudu is a mobile application designed to be used by churches to help
          them comply with the COVID-19 protocols guiding worship in churches by
          helping them observe all the set measures. It provides the following
          functionalities and features:
        </p>
        <div v-for="list in lists" :key="list" class="row py-2 mx-lg-3">
          <div class="col-1">
            <i class="bi bi-check-circle-fill"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
import TopBar from "./TopBar";
import Header from "./Header";
import Footer from "./Footer";
import SolutionHeader from "./SolutionHeader";
export default {
  name: 'abudu',
  components: {
    TopBar,
    Header,
    Footer,
    SolutionHeader
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      lists: [
        'Users can find churches near them. ',
        'Users to book Services from a church (service can be booked individually or as a group) ',
        'Church admin can create churches on the platform. ',
        'Church admin can create services with predetermined number of allowed congregants. ',
        'Can delete and edit services. ',
        'SMS communication to users'
      ]
    }
  }
}
</script>

<style scoped>
body {
  background-color: #ffffff !important;
}
i {
  color: #00a7ea;
}
.list {
  color: #000000;
  font: normal normal normal 1em Rubik;
}
.none-list {
  font: normal normal normal 1em/45px Rubik;
  color: #000000;
  opacity: 1;
}
.col-1 {
  width: 3% !important;
}
</style>
