<template>

  <div id="app">

    <router-view></router-view>

  </div>

</template>

<script>
// import jQuery from "jquery";
// const $ = jQuery;

export default {
  name: 'app',
  components: {}
}
</script>

<style scoped>
i.search{
  margin-top: 8px;
  font-size: 3em;
}
</style>