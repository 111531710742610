<template>
  <header
          id="header"
          class="fixed-top d-flex align-items-center"
          :class="[error ? 'header-scrolled' : '']"
  >
    <div class="container d-flex justify-content-between align-items-center">
      <div class="logo mb-4">
        <!--<h1><a href="index.html">SoftLand</a></h1>-->
        <!-- Uncomment below if you prefer to use an image logo -->
        <router-link to="/">
          <img
                  :src="`${publicPath}assets/img/logo.png`"
                  alt=""
                  class="img-fluid"
          />
        </router-link>
      </div>

      <nav id="navbar" class="navbar">
        <ul>
          <li>
            <router-link to="/">
              <a
                      class=" "
                      href="#"
                      :style="[color ? { color: '#0D4986' } : { color: '#0D4986' }]"
              >Home</a
              >
            </router-link>
          </li>
          <li class="dropdown">
            <a
                    href="#"
                    :style="[color ? { color: '#0D4986' } : { color: '#0D4986' }]"
            ><span>Services</span> <i class="bi bi-chevron-down"></i
            ></a>
            <ul>
              <li>
                <a href="#"
                ><router-link to="/web-application-development"
                >Web Apps Development</router-link
                ></a
                >
              </li>
              <li>
                <a href="#"
                ><router-link to="/web-design-development"
                >Web Design & Development</router-link
                ></a
                >
              </li>
              <li>
                <a href="#"
                ><router-link to="/mobile-app-development"
                >Mobile Apps Development
                </router-link></a
                >
              </li>
              <li>
                <a href="#"
                ><router-link to="/ussd-and-sms-development"
                >USSD & SMS Development</router-link
                ></a
                >
              </li>
              <li>
                <a href="#"
                ><router-link to="/custom-erp-development"
                >Custom ERP Development</router-link
                ></a
                >
              </li>
              <li>
                <a href="#"
                ><router-link to="/ui-ux-design-development"
                >UI/UX Design & Development
                </router-link></a
                >
              </li>
            </ul>
          </li>
          <li class="dropdown">
            <a
                    href="#"
                    :style="[color ? { color: '#0D4986' } : { color: '#0D4986' }]"
            ><span>Solutions</span> <i class="bi bi-chevron-down"></i
            ></a>
            <ul>
              <li>
                <a href="#"
                ><router-link to="/bonga-for-cash"
                >Bonga For Cash
                </router-link></a
                >
              </li>
              <li>
                <a href="#"
                ><router-link to="/vas-system"
                >VAS/SMS System
                </router-link></a
                >
              </li>
              <li>
                <a href="#"
                ><router-link to="/product-promotion-system"
                >Product Promotion System
                </router-link></a
                >
              </li>
              <li>
                <a href="#"
                ><router-link to="/school-management-system"
                >School Management System
                </router-link></a
                >
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/portfolio">
              <a
                      href="#"
                      :style="[color ? { color: '#0D4986' } : { color: '#0D4986' }]"
              >Portfolio</a
              >
            </router-link>
          </li>
          <li>
            <a
                    href="/#contactus"
                    :style="[color ? { color: '#0D4986' } : { color: '#0D4986' }]"
            >Contact</a
            >
          </li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <!-- .navbar -->
    </div>
  </header>
</template>
<script>
  export default {
    name: 'Header',
    props: {
      color: Boolean,
      error: Boolean
    },
    data() {
      return {
        publicPath: process.env.BASE_URL
      }
    },
  }
</script>

<style scoped></style>