<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />

  <!-- ======= Hero Section ======= -->

  <section
    class="hero-section"
    id="hero1"
    style="background: url('../assets/img/services/port.jpg') no-repeat center !important; background-size: contain !important;"
  ></section>

  <section class="section bg-white pt-0">
    <div class="container">
      <div class="row d-flex align-content-center">
        <!--<h2 class="text-center">
          <span style="color: #13396A">OUR </span>
          <span style="color: #0864B1">PORTFOLIO</span>
        </h2>-->
        <div class="row row-cols-1 row-cols-md-12 g-4">
          <div v-for="item in items" :key="item.url" class="col-md-4">
            <div class="card h-100 mt-0">
              <div class="text-center mt-2">
                <img
                  :src="`${publicPath}assets/img/portfolio/` + item.icon"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <div class="card-body pt-0 pb-0">
                <div class="name">{{ item.title }}</div>
                <div class="group text-black">{{ item.group }}</div>
                <div class="type">
                  <i class="bi bi-info-circle mx-2"></i>{{ item.type }}
                </div>
                <div class="desc">{{ item.desc }}</div>
              </div>
              <div class="card-footer d-flex justify-content-center">
                <router-link :to="{ name: item.url }">
                  <div class="btn btn-sm">Read More</div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
  import TopBar from "./TopBar";
  import Header from "./Header";

  import Footer from "./Footer";
export default {
  name: 'portfolio',
  components: {
    TopBar,
    Header,
    Footer
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      items: [
        {
          icon: 'bayer-logo.png',
          title: 'Crop Informatics System',
          group: 'USSD Application',
          type: 'Mobile',
          desc:
            'This is a USSD system used to educate farmers and avail crop solutions to them based on their need and crop they are growing. It provides the following features and functionalities:',
          url: 'crop-informatics'
        },
        {
          icon: 'focus-logo.png',
          title: 'Focus Kenya',
          group: 'Fellowship Management System',
          type: 'Web and Mobile',
          desc:
            'This is an online platform for buying, selling, and delivering fresh agricultural products from directly from the farms to the buyers',
          url: 'fellowship'
        },
        {
          icon: 'm-shamba-logo.jpg',
          title: 'M-Shamba',
          group: 'E-Commerce System',
          type: 'Web and Mobile',
          desc:
            'This is an online platform for buying, selling, and delivering fresh agricultural products from directly from the farms to the buyers',
          url: 'e-commerce'
        },
        {
          icon: 'wazibet-logo.jpg',
          title: 'Wazibet',
          group: 'Betting System',
          type: 'Web',
          desc: 'This a whole round betting platform',
          url: 'betting'
        },
        {
          icon: 'storiez-logo.jpg',
          title: 'Storiez App',
          group: 'Social App',
          type: 'Mobile',
          desc: 'Storiez is a social app for users to share their feelings.',
          url: 'storiez-app'
        },
        {
          icon: 'comprite-logo.jpg',
          title: 'Virtual AGM System',
          group: 'Management System',
          type: 'Web and Mobile',
          desc:
            'The AGM Management System is a USSD application for conducting Annual General Meetings or any other company meetings as an option for the normal physical meetings.',
          url: 'agm-system'
        },
        {
          icon: 'TeraTrig-logo.jpg',
          title: 'Trio Geos',
          group: 'Mapping Application',
          type: 'Web and Mobile',
          desc:
            'The Kisumu County App is a GIS mobile application used for mapping land features. It is used to capture coordinates, photos, videos, and description of the features ...',
          url: 'kisumu-map-app'
        },
        {
          icon: 'sqwodi-logo.jpg',
          title: 'Sqwodi',
          group: 'Management App',
          type: 'Mobile',
          desc:
            'Sqwodi is a mobile application that helps unskilled workers to get access to jobs to a wide range of clients and helps a user get the right service providers at the comfort of their comforts.',
          url: 'sqwodi'
        },
        {
          icon: 'abudu-logo.jpg',
          title: 'Abudu',
          group: 'Church Booking App',
          type: 'Mobile',
          desc:
            'Abudu is a mobile application designed to be used by churches to help them comply with the COVID-19 protocols guiding worship in churches by helping them observe all the set measures.',
          url: 'abudu'
        }
      ]
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
@import '../../public/assets/scss/material-dashboard';
.hero-section {
  min-height: 14em !important;
  height: 0;
}
h2 {
  font: normal normal bold 50px/25px Rubik;
  text-transform: uppercase;
}
.name {
  font: normal normal bold 19px/33px Rubik;
  color: #0864b1;
  opacity: 1;
}
.group {
  font: normal 900 normal 1em/30px Rubik;
  color: #000;
}
.desc {
  font: normal normal normal 1em/30px Open Rubik;
  letter-spacing: 0px;
  color: #000;
  text-align: justify;
}
.type {
  font: normal 900 normal 1em/30px Rubik;
  color: #000;
}
i {
  color: #676767;
}
.btn {
  border-radius: 4px;
  background-color: #0864b1;
}
.btn:hover {
  background-color: #0864b1;
}
.card {
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
}
@media screen and (max-width: 799px) {
  #hero1 {
    margin-top: 0;
  }
  .hero-section {
    min-height: 0 !important;
    height: 0;
  }
}
</style>
