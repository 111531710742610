<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />
  <main id="main">
    <solution-header
      name="Virtual AGM Management System"
      client="Comprite Kenya, subcontracted by Infinity Tech"
      type="USSD"
    />
  </main>
  <div class="container">
    <div class="row step my-5">
      <div class="col-md-5">
        <img
          :src="`${publicPath}assets/img/portfolio/agm.png`"
          class="img-fluid"
        />
      </div>
      <div class="col-md-7">
        <p class="none-list mt-lg-2">
          The AGM Management System is a USSD application for conducting Annual
          General Meetings or any other company meetings as an option for the
          normal physical meetings. online application where members will be
          able to interact with the application by dialing the USSD code
          provided. Some of the major features of the application include:
        </p>
      </div>
      <div class="col-12 mt-lg-4">
        <div v-for="list in lists.slice" :key="list" class="row py-2 mx-lg-3">
          <div class="col-1">
            <i class="bi bi-check-circle-fill"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
  import TopBar from "./TopBar";
  import Header from "./Header";
  import Footer from "./Footer";
  import SolutionHeader from "./SolutionHeader";
export default {
  name: 'agm-system',
  components: {
    TopBar,
    Header,
    Footer,
    SolutionHeader
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      lists: [
        'Enable members to access resources e.g., reports, ',
        'Vote and interact with other issues of the company remotely. ',
        'Pre-designed reporting with real-time tabulation and report generation. ',
        'Enhanced and additional user interfaces which provide users with much simpler data entry, updates, queries, and other capabilities. ',
        'Data export capabilities which allow users to export data to various software tools for simplified reporting and presentation capability.'
      ]
    }
  }
}
</script>

<style scoped>
body {
  background-color: #ffffff !important;
}
i {
  color: #00a7ea;
}
.list {
  color: #000000;
  font: normal normal normal 1em Rubik;
}
.col-1 {
  width: 3% !important;
}
.none-list {
  font: normal normal normal 1em/45px Rubik;
  color: #000000;
  opacity: 1;
}
</style>
