<template>
  <section id="services" class="">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-3 mt-3">
          <h2 class="welcome-title">
            <span style="color: #13396A">Our </span
            ><span style="color: #389AD1">Solutions</span>
          </h2>
        </div>
        <div
          v-for="item in items"
          :key="item.url"
          class="col-lg-3 col-md-6 my-3"
        >
          <div class="step-soln" style="/*min-height: 32em*/">
            <div class="step-inside-soln text-center">
              <img
                :src="'assets/img/soln/' + item.image"
                alt="Image"
                class="img-fluid mb-2 w-100"
                style="max-height: 12em"
              />
            </div>
            <div class="">
              <h3 class="step-title-soln">{{ item.title }}</h3>
              <p class="step-content-soln text-justify">
                {{ item.content }}
              </p>
              <div class="bottom-0">
                <p
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-offset="-500"
                  class="text-center d-grid gap-2"
                >
                  <router-link :to="{ name: item.url }">
                    <a
                      :href="item.url"
                      class="btn btn-link w-100"
                      style="color: rgb(13, 73, 134) !important; text-align: right; font-size: 1.2em; font-weight: bold;"
                      >Learn More</a
                    >
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Services',
  data() {
    return {
      items: [
        {
          image: 'btc1.png',
          bgColor: '#13396A',
          title: 'BONGA FOR CASH',
          content:
            'Bonga for Cash service enables Safaricom customers to redeem their bonga points and get instant cash in their MPESA accounts. The service is legitimate, easy, fast, and secure.',
          url: 'bonga'
        },
        {
          image: 'sms.jpg',
          bgColor: '#13396A',
          title: 'SMS/VAS SYSTEM',
          content:
            'Our robust bulk messaging platform guarantees fast, and 100% delivery of all messages sent, provide detailed and comprehensive reports and analytics to help users make informed decisions.',
          url: 'vas-system'
        },
        {
          image: 'prod.png',
          bgColor: '#13396A',
          title: 'PRODUCT PROMOTION SYSTEM',
          content:
            'Our product/service promotion system wholly automates the process of running and managing product/service campaigns and promotions',
          url: 'product-promotion'
        },
        {
          image: 'edu.png',
          bgColor: '#13396A',
          title: 'SCHOOL MANAGEMENT SYSTEM',
          content:
            'Our school management system is designed to be used in both nursery, primary, secondary, college and universities and is suited for both public and private academic institutions.',
          url: 'school-system'
        }
      ]
    }
  }
}
</script>

<style scoped>
a:hover {
  color: #ffffff;
}
.text-justify {
  text-align: justify;
}
</style>
