<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />

  <!-- ======= Hero Section ======= -->
  <hero-bg
    title="BONGA FOR CASH"
    content="Bonga for Cash service enables Safaricom customers to redeem their bonga points and get instant cash in their MPESA accounts. The service is legitimate, easy, fast, and secure."
    url="#"
    image="b2c.png"
    button="REDEEM NOW"
  />

  <main id="main">
    <!--    <section class="section bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="soln-text">
              Our product/service promotion system wholly automates the process
              of running and managing product/service campaigns and promotions.
              The system provides the following features and functionalities:
            </p>
          </div>
        </div>
      </div>
    </section>-->
    <section class="section">
      <div class="container">
        <!--<div class="row">
          <div class="col-12 text-center">
            <img
                    :src="base_url + 'assets/img/soln/redeem.png'"
                    class="img-fluid"
            />
          </div>
        </div>-->

        <div class="row">
          <div class="col-12">
            <h4
              class="text-center"
              style="color: #008000;font-size: 1.6em;margin-bottom: .7em;"
            >
              How to Redeem Your Bonga Points for Cash
            </h4>
          </div>
          <div class="card-deck row d-flex">
            <div class="col-lg-6 col-md-6 col-sm-12 d-flex " id="card1">
              <div
                class="card border-light mb-3 w-75"
                style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); margin: 0 auto;"
              >
                <div class="card-body">
                  <h5 class="card-title">
                    <span style="color: #000000; font-weight: 700;"
                      >Step 1: Set Your Service PIN</span
                    >
                  </h5>
                  <ol>
                    <li>Dial *126#</li>
                    <li>Select 1: Lipa na <b>Bonga Points</b></li>
                    <li>Select 2: <b>Paybill</b></li>
                    <li>Enter the <b>Business Number</b> - <b>644822</b></li>
                    <li>Enter the <b>Account Number</b> - <b>cash</b></li>
                    <li>Enter <b>Amount</b> (<b>in Cash</b>)</li>
                    <li>Select 1: <b>Accept Payment</b></li>
                    <li>Enter Service Pin - 0</li>
                    <li>Enter National ID</li>
                    <li>Enter New PIN (e.g., 1234)</li>
                    <li>Confirm New PIN (1234)</li>
                  </ol>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-12 d-flex" id="card2">
              <div
                class="card border-light mb-3 w-75"
                style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); margin: 0 auto;"
              >
                <div class="card-body">
                  <h5 class="card-title">
                    <span style="color: #000000; font-weight: 700;"
                      >Step 2: Redeem Your Bonga Points</span
                    >
                  </h5>
                  <ol>
                    <li>Dial *126#</li>
                    <li>Select 1: Lipa na <b>Bonga Points</b></li>
                    <li>Select 2:<b>Paybill</b></li>
                    <li>Enter the <b>Business Number</b> - <b>644822</b></li>
                    <li>Enter the <b>Account Number</b> - <b>cash</b></li>
                    <li>Enter <b>Amount</b> (<b>in Cash</b>)</li>
                    <li>Confirm <b>Payment</b></li>
                    <li>
                      Enter <b>Service Pin</b> (e.g., 1234 - as set in step 1
                      above)
                    </li>
                    <li>You will receive Cash in your MPESA instantly</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
import TopBar from "./TopBar";
import Header from "./Header";
import HeroBg from "./HeroBg";

import Footer from "./Footer";
export default {
  name: 'product-promotion',
  components: {
    TopBar,
    Header,
    HeroBg,
    Footer
  },
  data() {
    return {
      base_url: this.$store.state.base_url,
      lists: [
        'Set up a campaign or promotion for a given product or service. ',
        'Set the campaign dates. ',
        'Add promotion/campaign rules. ',
        'Automatically select winners. ',
        'Communicate to winners and target customers. ',
        'Detailed campaign reports and analysis.'
      ]
    }
  }
}
</script>

<style scoped>
.app-text {
  text-align: center;
  font: normal normal bold 20px/30px Roboto;
  color: #0864b1;
  text-transform: uppercase;
  opacity: 1;
}
i {
  color: #0864b1;
}
.list {
  color: #000000;
  font: normal normal normal 23px Biko;
}
.col-1 {
  width: 3% !important;
}
.ready {
  text-align: center;
  font: normal normal normal 43px/32px Montserrat;
  letter-spacing: 1.63px;
  color: #17479d;
  text-transform: uppercase;
  opacity: 1;
}
.btn-ready {
  background: #17479d 0% 0% no-repeat padding-box;
  border-radius: 42px;
  text-align: center;
  font: normal normal normal 30px/25px Montserrat;
  letter-spacing: 1.63px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}
li {
  padding: 0.5em 0;
  color: #000000;
}
b {
  font-weight: 900;
  font-size: 1.14em;
}
</style>
