<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />

  <!-- ======= Hero Section ======= -->
  <hero-bg
    title="PRODUCT/ SERVICE PROMOTION SYSTEM"
    content="Run and manage your product/service campaigns and promotions using our platform."
    url="#"
    image="product-promo.png"
    button="Get Solution"
  />

  <main id="main">
    <section class="section bg-white pb-0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="soln-text">
              Our product/service promotion system wholly automates the process
              of running and managing product/service campaigns and promotions.
              The system provides the following features and functionalities:
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section pt-0">
      <div class="container">
        <div v-for="list in lists" :key="list" class="row py-2">
          <div class="col-1">
            <i class="bi bi-check2-circle"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
        <div class="row d-flex justify-content-center mt-5">
          <div class="col-auto d-flex align-items-center">
            <span class="ready px-4">ready?</span>
            <router-link to="/">
              <button class="btn btn-lg btn-ready">GET SOLUTION</button>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </main>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
  import TopBar from "./TopBar";
  import Header from "./Header";
  import HeroBg from "./HeroBg";

  import Footer from "./Footer";
export default {
  name: 'product-promotion',
  components: {
    TopBar,
    Header,
    HeroBg,
    Footer
  },
  data() {
    return {
      base_url: this.$store.state.base_url,
      lists: [
        'Set up a campaign or promotion for a given product or service. ',
        'Set the campaign dates. ',
        'Add promotion/campaign rules. ',
        'Automatically select winners. ',
        'Communicate to winners and target customers. ',
        'Detailed campaign reports and analysis.'
      ]
    }
  }
}
</script>

<style scoped>
.app-text {
  text-align: center;
  font: normal normal bold 20px/30px Roboto;
  color: #0864b1;
  text-transform: uppercase;
  opacity: 1;
}
i {
  color: #0864b1;
}
.list {
  color: #000000;
  font: normal normal normal 1em Rubik;
}
.col-1 {
  width: 3% !important;
}
.ready {
  text-align: center;
  font: normal normal normal 2em/32px Rubik;
  letter-spacing: 1.63px;
  color: #17479d;
  text-transform: uppercase;
  opacity: 1;
}
.btn-ready {
  background: #17479d 0% 0% no-repeat padding-box;
  border-radius: 42px;
  text-align: center;
  font: normal normal normal 2em/25px Rubik;
  letter-spacing: 1.63px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}
</style>
