<template>
  <section id="services" class="section">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-4 mt-4">
          <h2 class="welcome-title">
            <span style="color: #13396A">Our </span
            ><span style="color: #389AD1">Services</span>
          </h2>
        </div>
        <div v-for="item in items" :key="item.url" class="col-lg-4 my-3">
          <div class="step">
            <div class="text-center">
              <img
                :src="'assets/img/dev/' + item.image"
                alt="Image"
                class="img-fluid w-100 mb-2"
                style="max-height: 14em;"
              />
            </div>
            <div class="step-inside">
              <h3 class="step-title">{{ item.title }}</h3>
              <p class="step-content text-justify">
                {{ item.content }}
              </p>
              <router-link :to="{ name: item.url }">
                <p
                  data-aos="fade-right"
                  data-aos-delay="200"
                  data-aos-offset="-500"
                  class="text-center d-grid gap-2"
                >
                  <a
                    href="javascript:void(0)"
                    class="btn btn-link"
                    style="color: #0D4986 !important; text-align: right;font-size: 1.2em; font-weight: bold;"
                  >
                    Learn More
                  </a>
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Services',
  data() {
    return {
      base_url: this.$store.state.base_url,
      publicPath: process.env.BASE_URL,
      items: [
        {
          image: 'web_dev.png',
          bgColor: '#085FA9',
          title: 'WEB APPS DEVELOPMENT',
          content:
            'We design and develop reliable, scalable, fast, mobile responsive and efficient custom web applications.',
          url: 'web-app-dev'
        },
        {
          image: 'img1.png',
          bgColor: '#389AD1',
          title: 'WEB DESIGN AND DEVELOPMENT',
          content:
            'We design beautiful, modern, and responsive websites customized to reflect our clients’ businesses.',
          url: 'web-design-dev'
        },
        {
          image: 'mob_app.jpg',
          bgColor: '#085FA9',
          title: 'MOBILE APPS DEVELOPMENT',
          content:
            'We use the latest mobile app development tools and technologies to develop secure mobile apps.',
          url: 'mobile-app-dev'
        },
        {
          image: 'ussd1.png',
          bgColor: '#085FA9',
          title: 'USSD APPLICATIONS',
          content:
            'We provide powerful USSD and SMS solutions to help businesses drive purchase.',
          url: 'ussd-sms-dev'
        },
        {
          image: 'erp1.png',
          bgColor: '#103C55',
          title: 'CUSTOM ERP SYSTEMS',
          content:
            'We develop customized ERP systems to help businesses and organizations bring all their operations into one single software solution.',
          url: 'custom-erp-dev'
        },
        {
          image: 'ui.png',
          bgColor: '#389AD1',
          title: 'UI/UX DESIGN & DEVELOPMENT',
          content:
            'We provide top notch UI/UX development that have eye catching look and feel and exciting user experience.',
          url: 'ui-design-dev'
        }
      ]
    }
  }
}
</script>

<style scoped>
.text-justify {
  text-align: justify;
}
a {
  color: white;
}
a:hover {
  color: white;
}
</style>
