<template>
  <div>
    <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />

  <!-- ======= Hero Section ======= -->
  <hero-bg
    title="USSD & SMS Applications"
    content="Achieve high-speed and interactive communications with your customers over any kind of cell phone."
    url="#"
    image="USSD.png"
    button="GET SERVICE"
  />

  <main id="main">
    <ussd-content />
  </main>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
import TopBar from "./TopBar";
import Header from "./Header";
import HeroBg from "./HeroBg";
import UssdContent from "./UssdContent";

import Footer from "./Footer";
export default {
  name: 'ussd-sms-dev',
  components: {
    TopBar,
    Header,
    HeroBg,
    UssdContent,
    Footer
  }
}
</script>

<style scoped></style>
