import { render, staticRenderFns } from "./WebDesignDev.vue?vue&type=template&id=39ec9ecc&scoped=true&"
import script from "./WebDesignDev.vue?vue&type=script&lang=js&"
export * from "./WebDesignDev.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ec9ecc",
  null
  
)

export default component.exports