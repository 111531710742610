<template>
  <div class="card ">
    <div v-html="response_text"></div>
    <div class="form-title mb-2 mt-1">GET FREE QUOTE NOW</div>
    <div class="card-body ">
      <div class="form-group">
        <div class="row">
          <div class="col-2 d-flex align-items-center align-content-center">
            <img :src="`${publicPath}assets/img/icons/user.svg`" />
          </div>
          <div class="col-10">
            <label class="bmd-label-floating"></label>
            <input
              type="email"
              class="form-control"
              placeholder="Your full name"
              v-model="fullName"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-2 d-flex align-items-center align-content-center">
            <img :src="`${publicPath}assets/img/icons/mail.svg`" />
          </div>
          <div class="col-10">
            <label class="bmd-label-floating"></label>
            <input
              type="email"
              class="form-control"
              placeholder="Your email address"
              v-model="email"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-2 d-flex align-items-center align-content-center">
            <img :src="`${publicPath}assets/img/icons/phone.svg`" />
          </div>
          <div class="col-10">
            <label class="bmd-label-floating"></label>
            <input
              type="email"
              class="form-control"
              placeholder="Your phone no"
              v-model="msisdn"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-2 d-flex align-items-center align-content-center">
            <img :src="`${publicPath}assets/img/icons/description.svg`" />
          </div>
          <div class="col-10">
            <label class="bmd-label-floating"></label>
            <textarea
              cols="3"
              class="form-control"
              placeholder="Brief Project Description"
              v-model="message"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer ml-auto mr-auto">
      <div class="btn btn-submit" @click="submitForm">SUBMIT</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormQuote',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      response_text: '',
      fullName: '',
      msisdn: '',
      email: '',
      subject: 'GET FREE QUOTE NOW',
      message: '',
      recipient: process.env.VUE_APP_RECIPIENTS,
      configs: {
        smtp: process.env.VUE_APP_SMTP,
        smtpPort: process.env.VUE_APP_SMTP_PORT,
        username: process.env.VUE_APP_USERNANE,
        password: process.env.VUE_APP_PASSWORD,
        senderID: process.env.VUE_APP_SENDER_ID
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import 'public/assets/scss/material-dashboard.scss';
.card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #dbd8ea80;
  border-radius: 20px;
}
</style>
