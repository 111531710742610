<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />
  <main id="main">
    <solution-header
      name="Social App (Storiez)"
      client="Ally Innovations"
      type="Mobile"
    />
  </main>
  <div class="container">
    <div class="row step my-5">
      <div class="col-md-5">
        <img
          :src="`${publicPath}assets/img/portfolio/stories-ke.jpg`"
          class="img-fluid"
        />
      </div>
      <div class="col-md-7">
        <p class="list mt-lg-2">
          Storiez is a social app for users to share their feelings. It provides
          the following features and functionalities for users:
        </p>
        <div
          v-for="list in lists.slice(6)"
          :key="list"
          class="row py-2 mx-lg-3"
        >
          <div class="col-1">
            <i class="bi bi-check-circle-fill"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
        <div
          v-for="list in lists.slice(0, 6)"
          :key="list"
          class="row py-2 mx-lg-3"
        >
          <div class="col-1">
            <i class="bi bi-check-circle-fill"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
      </div>
      <!--<div class="col-md-10 mt-lg-4">

      </div>-->
    </div>
  </div>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
  import TopBar from "./TopBar";
  import Header from "./Header";
  import Footer from "./Footer";
  import SolutionHeader from "./SolutionHeader";
export default {
  name: 'storiez-app',
  components: {
    TopBar,
    Header,
    Footer,
    SolutionHeader
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      lists: [
        'With account-based system, you are not locked to a specific device. ',
        'Share documents, videos, and audio of various sizes. ',
        'When disconnected from internet, downloads resume from where they left ',
        'Unlimited messages whether in groups or peer to peer. ',
        'Availability of private groups and public groups with unlimited size ',
        'Create temporary groups whether via hotspot or Internet based, Simple fast and affordable',
        'With account-based system, you are not locked to a specific device. ',
        'Share documents, videos, and audio of various sizes. ',
        'When disconnected from internet, downloads resume from where they left ',
        'Unlimited messages whether in groups or peer to peer. ',
        'Availability of private groups and public groups with unlimited size ',
        'Create temporary groups whether via hotspot or Internet based, Simple fast and affordable'
      ]
    }
  }
}
</script>

<style scoped>
body {
  background-color: #ffffff !important;
}
i {
  color: #00a7ea;
}
.list {
  color: #000000;
  font: normal normal normal 1em Rubik;
}
.col-1 {
  width: 3% !important;
}
</style>
