<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />

  <!-- ======= Hero Section ======= -->
  <hero-bg
    title="WEB design & development"
    content="We design beautiful, modern, and responsive websites customized to reflect our clients' businesses."
    url="#"
    image="web-design.png"
    button="GET SERVICE"
  />

  <main id="main">
    <solution-content
      content="Your website is an important tool that complements your marketing
            efforts. A well-designed website gives prospective clients
            confidence in your services and generate new leads. We design
            beautiful, modern, and responsive websites customized to reflect our
            clients’ businesses. To achieve this, we first carry out in-depth
            analysis to understand your business.We then work with you to come
            up with a modern and beautiful design that is most suitable for your
            business. Once your satisfied with the designs, they are handed over
            to our development team for implementation."
    />
  </main>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
import TopBar from "./TopBar";
import Header from "./Header";
import HeroBg from "./HeroBg";
import SolutionContent from "./SolutionContent";

import Footer from "./Footer";
export default {
  name: 'web-design-dev',
  components: {
    TopBar,
    Header,
    HeroBg,
    SolutionContent,
    Footer
  }
}
</script>

<style scoped></style>
