<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />
  <main id="main">
    <solution-header
      name="FELLOWSHIP MANAGEMENT SYSTEM"
      client="Focus Kenya"
      type="Web and Mobile"
    />
  </main>
  <div class="container">
    <div class="row step my-5">
      <div class="col-md-5">
        <img
          :src="`${publicPath}assets/img/portfolio/focus.jpg`"
          class="img-fluid"
        />
      </div>
      <div class="col-md-7">
        <p class="none-list mt-lg-2">
          This is both a web and mobile application for Bible study and
          fellowship management. It is used to efficiently manage different
          departments and fellowships in Christian unions and churches. Some of
          the main features of the system includes:
        </p>
        <div v-for="list in lists" :key="list" class="row py-2 mx-lg-3">
          <div class="col-1">
            <i class="bi bi-check-circle-fill"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
  import TopBar from "./TopBar";
  import Header from "./Header";
  import Footer from "./Footer";
  import SolutionHeader from "./SolutionHeader";
export default {
  name: 'fellowship',
  components: {
    TopBar,
    Header,
    Footer,
    SolutionHeader
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      lists: [
        'Membership Management ',
        'Groups/Fellowship/Departments Management ',
        'Automatic allocations of group members ',
        'Automatic Assignment of group leaders ',
        'Automatic identification of future group leaders ',
        'Attendance capturing and attendance reports. ',
        'Communication – sms & email ',
        'Follow Ups Management ',
        'Detailed reports and analysis at various levels'
      ]
    }
  }
}
</script>

<style scoped>
body {
  background-color: #ffffff !important;
}
i {
  color: #00a7ea;
}
.list {
  color: #000000;
  font: normal normal normal 1em Rubik;
}
.none-list {
  font: normal normal normal 1em/45px Rubik;
  color: #000000;
  opacity: 1;
}
.col-1 {
  width: 3% !important;
}
</style>
