<template>
  <section class="hero-section" id="hero1">
    <div class="wave">
      <!--<img src="assets/img/bg-img1.png" alt="alt" class="custom-image img-fluid" />-->
    </div>

    <div class="container">
      <div class="row">
        <div class="col-12 hero-text-image bg-text-mt">
          <div class="row">
            <div class="col-lg-7 text-center text-lg-start">
              <h1
                data-aos="fade-right"
                class="text-1"
                style="line-height: 1.2em"
              >
                Using Technology and Innovation To Enhance SMEs Growth.
              </h1>
              <div class="row">
                <div class="col-6 header-box">
                  <p
                    class="mb-4 text-white"
                    data-aos="fade-right"
                    data-aos-delay="100"
                  >
                    We are a technology & innovations company focusing on
                    providing technological innovations to enhance the growth of
                    SMEs globally. We use the latest and best technologies to
                    deliver customized technology solutions that are secure,
                    reliable, affordable, and meet the specific needs of SMEs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection'
}
</script>

<style scoped>
.header-box > p {
  text-align: justify;
}
.mb-10 {
  margin-bottom: 26rem !important;
}
</style>
