import Vue from 'vue'
import Vuex from 'vuex'
//import VuexPersistence from 'vuex-persist'
// import axios from 'axios'

Vue.use(Vuex);

/*
const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})
*/



export default new Vuex.Store({
    state: {
        // base_url: 'https://' + window.location.host + '/'
        base_url: 'http://' + window.location.host + '/'
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {}
    //plugins: [vuexLocal.plugin]
})