<template>
  <section class="section border-top pb-1">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 mb-5">
          <h2 class="welcome-title">
            <span style="color: #13396A">Clients & </span
            ><span style="color: #389AD1">Partners</span>
          </h2>
        </div>
        <div class="row step pt-5">
          <div
            v-for="logo in logos"
            :key="logo"
            class="col-auto text-center mb-5"
          >
            <img
              :src="'assets/img/client/' + logo"
              alt="Image"
              class="img-fluid"
              style="max-height: 70px"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Partners',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      logos: [
        'focus.png',
        'infinity.png',
        'm-shamba.png',
        'sh.png',
        'silicon.png',
        'sms.png',
        'sqwodi.png',
        'storiez.png',
        'synergy.png',
        'abudu.png'
      ]
    }
  }
}
</script>

<style scoped></style>
