<template>
  <section>
    <footer class="footer" role="contentinfo" id="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img
              :src="`${publicPath}assets/img/logo-white.png`"
              class="img-fluid"
              alt="alt"
              style="height: 100px; margin-top: -30px"
            />
            <div class="col-md-10 mb-4 mb-md-0 text-white">
              <div class="row">
                <div class="col-2">
                  <i class="bi bi-geo-alt-fill"></i>
                </div>
                <p class="col-10" style="line-height: 28px">
                  No. 246A Owashika Road, Lavington.,
                  <br />
                  Lavington, Kenya.
                </p>
              </div>
              <div class="row">
                <div class="col-2">
                  <i class="bi bi-envelope-fill"></i>
                </div>
                <p class="col-10">
                  info@legitimate-tech.com
                </p>
              </div>
              <div class="row">
                <div class="col-2">
                  <i class="bi bi-telephone-fill"></i>
                </div>
                <p class="col-10" style="line-height: 28px">
                  +254 112 899 208 <br />
                  +254 733 333 877
                </p>
              </div>
            </div>
            <p class="text-white"><strong>Stay connected with us</strong></p>
            <p class="social">
              <a href="#" class="mx-2"><span class="bi bi-twitter"></span></a>
              <a href="#" class="mx-2"><span class="bi bi-facebook"></span></a>
              <a href="#" class="mx-2"><span class="bi bi-instagram"></span></a>
              <a href="#" class="mx-2"><span class="bi bi-linkedin"></span></a>
            </p>
          </div>
          <div class="col-md-3 text-white">
            <h2 class="text-white"><strong>Solutions</strong></h2>
            <p v-for="soln in solutions" :key="soln.title">
              <router-link :to="{ path: soln.url_path }">
                {{ soln.title }}
              </router-link>
            </p>
          </div>
          <div class="col-md-3 text-white">
            <h2 class="text-white"><strong>Services</strong></h2>
            <p v-for="soln in services" :key="soln.title">
              <router-link :to="{ path: soln.url_path }">
                {{ soln.title }}
              </router-link>
            </p>
          </div>
          <div class="col-md-2 text-white">
            <h2 class="text-white"><strong>Industries</strong></h2>
            <p v-for="soln in industries" :key="soln.title">
              <router-link :to="{ path: soln.url_path }">
                {{ soln.title }}
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <div class="border-top">
        <div class="container">
          <div class="row">
            <p class="text-white text-center mt-3">
              <strong>&copy; 2021 SMEs Tech Innovations</strong>
            </p>
          </div>
        </div>
      </div>
    </footer>

    <a href="#"
      class="back-to-top d-flex align-items-center justify-content-center"
      ><i class="bi bi-arrow-up-short"></i
    ></a>
  </section>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      solutions: [
        {
          title: 'Bonga to Cash',
          url_path: '/solutions/bonga-for-cash'
        },
        {
          title: 'SMS/VAS System',
          url_path: '/solutions/vas-system'
        },
        {
          title: 'Product Promotion System',
          url_path: '/solutions/product-promotion-system'
        },
        {
          title: 'School Management System',
          url_path: '/solutions/school-management-system'
        }
      ],
      services: [
        {
          title: 'Web Application Development',
          url_path: '/services/web-application-development'
        },
        {
          title: 'Web Design and Development',
          url_path: '/services/web-design-development'
        },
        {
          title: 'USSD Application Development',
          url_path: '/services/ussd-and-sms-development'
        },
        {
          title: 'Custom ERP Systems',
          url_path: '/services/custom-erp-development'
        },
        {
          title: 'UI/UX Design and Development',
          url_path: '/services/ui-ux-design-development'
        }
      ],
      industries: [
        {
          title: 'Finance',
          url_path: '/'
        },
        {
          title: 'Education',
          url_path: '/'
        },
        {
          title: 'Marketing',
          url_path: '/'
        },
        {
          title: 'Sports',
          url_path: '/'
        },
        {
          title: 'Real Estate',
          url_path: '/'
        },
        {
          title: 'Retail',
          url_path: '/'
        }
      ]
    }
  }
}
</script>

<style scoped></style>
