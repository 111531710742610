<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />
  <main id="main">
    <solution-header name="E-Commerce System" client="M-Shamba" type="Web" />
  </main>
  <div class="container">
    <div class="row step my-5">
      <div class="col-md-5">
        <img
          :src="`${publicPath}assets/img/portfolio/commerce.jpg`"
          class="img-fluid"
        />
      </div>
      <div class="col-md-7">
        <p class="none-list mt-lg-2">
          This is an online platform for buying, selling, and delivering fresh
          agricultural products from directly from the farms to the buyers. It
          has the following modules:
        </p>
        <div
          v-for="list in lists.slice(0, 5)"
          :key="list"
          class="row py-2 mx-lg-3"
        >
          <div class="col-1">
            <i class="bi bi-check-circle-fill"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
      </div>
      <!--<div class="col-12 mt-lg-4">

      </div>-->
    </div>
  </div>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
  import TopBar from "./TopBar";
  import Header from "./Header";
  import Footer from "./Footer";
  import SolutionHeader from "./SolutionHeader";
export default {
  name: 'e-commerce',
  components: {
    TopBar,
    Header,
    Footer,
    SolutionHeader
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      lists: [
        'Sellers to add products to be sold, adding photos and descriptions of the products. ',
        'Customers buy and order online. ',
        'Resource pulling and group buying. ',
        'MPESA and Card payments ',
        'Integrate with third party for delivery applications like Sendy, uber and bolt. ',
        'Keep track of the delivery statuses. ',
        'Send SMS communications. ',
        'Mobile and online payments. ',
        'Detailed reports and analytics.'
      ]
    }
  }
}
</script>

<style scoped>
body {
  background-color: #ffffff !important;
}
i {
  color: #00a7ea;
}
.list {
  color: #000000;
  font: normal normal normal 1em Rubik;
}
.col-1 {
  width: 3% !important;
}
.none-list {
  font: normal normal normal 1em/45px Rubik;
  color: #000000;
  opacity: 1;
}
</style>
