<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />

  <!-- ======= Hero Section ======= -->
  <hero-bg
    title="MOBILE APPLICATION DEVELOPMENT"
    content="We develop secure mobile applications that meets the specific needs of our customers"
    url="#"
    image="app.png"
    button="GET SERVICE"
  />

  <main id="main">
    <section class="section bg-white p-0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="soln-text">
              We use the latest mobile app development tools and technologies to
              develop secure mobile applications that meets the specific needs
              of our customers. We develop native applications that runs
              specifically on iOS, Android, Blackberry and Windows Mobile. We
              also develop hybrid applications that can run on any of the
              above-mentioned platforms without having to develop individual
              applications for each platform.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section p-0">
      <div class="container">
        <div class="row" style="background-color: #F8FCFF">
          <div class="col-md-7">
            <div
              v-for="app in apps"
              :key="app.name"
              class="row mx-5 my-5 d-flex"
            >
              <div class="col-md-3">
                <img
                  :src="base_url + 'assets/img/icons/' + app.image"
                  class="img-fluid"
                />
              </div>
              <div class="col-md-4">
                <span class="app-text">{{ app.name }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <form-quote />
          </div>
        </div>
      </div>
    </section>
  </main>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
import TopBar from "./TopBar";
import Header from "./Header";
import HeroBg from "./HeroBg";
import FormQuote from "./FormQuote";

import Footer from "./Footer";
export default {
  name: 'mobile-app-dev',
  components: {
    TopBar,
    Header,
    HeroBg,
    FormQuote,
    Footer
  },
  data() {
    return {
      base_url: this.$store.state.base_url,
      apps: [
        {
          image: 'android-app.png',
          name: 'ANDROID APP DEVELOPMENT'
        },
        {
          image: 'ios-app.png',
          name: 'iOS app DEVELOPMENT'
        },
        {
          image: 'cross-platform.png',
          name: 'CROSS PLATFORM APP DEVELOPMENT'
        }
      ]
    }
  }
}
</script>

<style scoped>
.app-text {
  text-align: center;
  font: normal normal bold 1em/30px Rubik;
  color: #0864b1;
  text-transform: uppercase;
  opacity: 1;
}
</style>
