<template>
  <div>
  <section class="section">
    <div class="container">
      <div class="row">
        <p>
          Our customized USSD applications help you achieve high-speed and
          interactive communications with your customers over any kind of cell
          phone. It enables your customers to access your services using all
          kinds of mobile phones from the simplest feature phone (Mulika Mwizi
          or Kabambe) to the most complicated high-end smart phone to access
          your services. It is also easy to use and remember and involves
          dialing a specific short sequence of characters on their handset
          starting with a star sign * and ending with a hash #, such as *144#.
        </p>
        <h3>SMS Application</h3>
        <p>
          Our SMS applications enables you to realize increased sales & customer
          satisfaction by conveniently reaching your customers. We guarantee
          fast and 100% delivery of all messages sent, and we provide you with
          detailed and comprehensive reports and analytics to help you make
          informed decisions.
        </p>
      </div>
      <div class="row">
        <div v-for="item in items" :key="item.code" class="col-md-6">
          <div class="ussd-card">
            <div class="row d-flex justify-content-center">
              <div class="col-auto my-3">
                <div class="ussd-code">
                  <span>{{ item.code }}</span>
                </div>
              </div>
            </div>
            <div class="text-center">
              <span class="title">{{ item.title }}</span>
            </div>
            <hr />
            <p class="p-4">{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="row py-4">
    <img :src="`${publicPath}assets/img/services/ussd-features.png`" />
  </div>
  <section class="section">
    <div class="container">
      <div class="row telcom mx-2">
        <h2>Shared USSD PRICING</h2>
        <table class="table table-bordered">
          <thead>
            <tr>
              <td>Type</td>
              <td>Cost</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Set Up Cost</td>
              <td>Free</td>
            </tr>
            <tr>
              <td>Maintenance Cost</td>
              <td>KES 5,800</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row telcom mx-2 my-4">
        <h2>Dedicated USSD pricing</h2>
        <div class="col-12 my-4">
          <h3 style="color: #058A13">Safaricom</h3>
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>Type</td>
                <td>Cost</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Set Up Cost</td>
                <td>KES 100,000</td>
              </tr>
              <tr>
                <td>Maintenance Cost</td>
                <td>KES 5,800</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 my-4">
          <h3 style="color: #F50B0B">Airtel</h3>
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>Type</td>
                <td>Cost</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Set Up Cost</td>
                <td>KES 100,000</td>
              </tr>
              <tr>
                <td>Maintenance Cost</td>
                <td>KES 5,800</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 my-4">
          <h3 style="color: #0BEDF5">Telkom</h3>
          <table class="table table-bordered">
            <thead>
              <tr>
                <td>Type</td>
                <td>Cost</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Set Up Cost</td>
                <td>KES 100,000</td>
              </tr>
              <tr>
                <td>Maintenance Cost</td>
                <td>KES 5,800</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-auto d-flex align-items-center">
          <span class="ready px-4">ready?</span>
          <router-link to="/">
            <button class="btn btn-lg btn-ready">GET SERVICE</button>
          </router-link>
        </div>
      </div>
    </div>
  </section>
  </div>
</template>

<script>
export default {
  name: 'UssdContent',
  data() {
    return {
      base_url: this.$store.state.base_url,
      publicPath: process.env.BASE_URL,
      items: [
        {
          code: '*123#',
          title: 'Dedicated USSD',
          desc:
            'This is a three digit code for example *123# that is used solely by one user for their company or service'
        },
        {
          code: '*123*12#',
          title: 'Shared USSD',
          desc:
            'This code is used by multiple companies or services. Each company is allocated a channel on a dedicated code e.g.  *123*12#'
        }
      ]
    }
  }
}
</script>

<style scoped>
p {
  text-align: left;
  font: normal normal normal 1em/2.5em Rubik;
  color: #000;
  opacity: 1;
}
h3 {
  color: #298fca;
}
.ussd-card {
  background-color: #2d91cb;
  border-radius: 44px;
  opacity: 1;
  min-height: 18.8em;
}
.ussd-code {
  background-color: #ffffff;
  border: 1px solid #707070;
  border-radius: 12px;
  opacity: 1;
}
.ussd-code span {
  font: normal normal 600 36px/35px Montserrat;
  letter-spacing: 1.66px;
  color: #113768;
  text-transform: uppercase;
}
.ussd-card p {
  text-align: left;
  font: normal normal normal 1.2em/35px Rubik;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  max-height: 10em;
}
.ussd-card .title {
  text-align: center;
  font: normal normal 600 28px/35px Montserrat;
  letter-spacing: 1.29px;
  color: #ffffff;
  text-transform: uppercase;
}
.ussd-card hr {
  border: 1px solid #ffffff;
  opacity: 1;
}
.telcom h2 {
  text-align: center;
  font: normal normal normal 26px/27px Montserrat;
  letter-spacing: 1.25px;
  color: #17479d;
  text-transform: uppercase;
}
.telcom h3 {
  font: normal normal normal 33px/32px Montserrat;
  letter-spacing: 1.25px;
}
.telcom table {
  border: 2px solid #1d2157;
}
.telcom table thead {
  text-align: center;
  font: normal normal normal 20px/32px Montserrat;
  letter-spacing: 0.76px;
  color: #ffffff;
  background-color: #1d2157;
}
.telcom table tr td {
  text-align: center;
  font: normal normal normal 18px/45px Montserrat;
  letter-spacing: 0.87px;
  color: #1c1f55;
  opacity: 1;
}
.ready {
  text-align: center;
  font: normal normal normal 43px/32px Montserrat;
  letter-spacing: 1.63px;
  color: #17479d;
  text-transform: uppercase;
  opacity: 1;
}
.btn-ready {
  background: #17479d 0% 0% no-repeat padding-box;
  border-radius: 42px;
  text-align: center;
  font: normal normal normal 30px/25px Montserrat;
  letter-spacing: 1.63px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}
</style>
