<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />
  <main id="main">
    <solution-header
      name="Crop Informatics System"
      client="Bayer Global, subcontracted by Infinity Tech"
      type="USSD"
    />
  </main>
  <div class="container">
    <div class="row step my-5">
      <div class="col-md-5">
        <img
          :src="`${publicPath}assets/img/portfolio/crop.jpg`"
          class="img-fluid"
        />
      </div>
      <div class="col-md-7">
        <p class="none-list mt-lg-2">
          This is a USSD system used to educate farmers and avail crop solutions
          to them based on their need and crop they are growing. It provides the
          following features and functionalities:
        </p>
        <div v-for="list in lists" :key="list" class="row py-2 mx-lg-3">
          <div class="col-1">
            <i class="bi bi-check-circle-fill"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
      </div>
      <!--<div class="col-12 mt-lg-4">

      </div>-->
    </div>
  </div>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
  import TopBar from "./TopBar";
  import Header from "./Header";
  import Footer from "./Footer";
  import SolutionHeader from "./SolutionHeader";
export default {
  name: 'crop-informatics',
  components: {
    TopBar,
    Header,
    Footer,
    SolutionHeader
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      lists: [
        'Farmers register and subscribe for service through USSD channel. ',
        'Farmers receive updates, news and guidance through SMS based on their need. ',
        'Admin can send or schedule bulk SMS to farmers. ',
        'Creation, Update, Viewing and Deletion of various crops with their various diseases and solutions attached to the crop with which farmers will register with and updates. ',
        'Creation, Update, Viewing and Deletion of various seeds with farmers will register with and updates. User management, user roles and permissions'
      ]
    }
  }
}
</script>

<style scoped>
body {
  background-color: #ffffff !important;
}
i {
  color: #00a7ea;
}
.list {
  color: #000000;
  font: normal normal normal 1em Rubik;
}
.col-1 {
  width: 3% !important;
}
.none-list {
  font: normal normal normal 1em/45px Rubik;
  color: #000000;
  opacity: 1;
}
</style>
