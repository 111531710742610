<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />

  <!-- ======= Hero Section ======= -->
  <hero-bg
    title="SCHOOL MANAGEMENT SYSTEM"
    content="We cover all the operations in a school right from student admission to student leaving."
    url="#"
    image="school-system.png"
    button="Get Solution"
  />

  <main id="main">
    <section class="section bg-white pb-0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="soln-text">
              Our school management system is an all-round, complete, and
              integrated school automation software suited for any school or
              academic institution of any level and size and can be used in
              nursery schools, primary, secondary, college or university. The
              system covers all the operations in a school right from student
              admission to when a student completes their studies in the school
              and is leaving. It provides the following features and
              functionalities:
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section pt-0">
      <div class="container">
        <div v-for="list in lists" :key="list" class="row py-2">
          <div class="col-1">
            <i class="bi bi-check2-circle"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
        <div class="row d-flex justify-content-center mt-5">
          <div class="col-auto d-flex align-items-center">
            <span class="ready px-4">ready?</span>
            <router-link to="/">
              <button class="btn btn-lg btn-ready">GET SOLUTION</button>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </main>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
import TopBar from "./TopBar";
import Header from "./Header";
import HeroBg from "./HeroBg";

import Footer from "./Footer";
export default {
  name: 'mobile-app-dev',
  components: {
    TopBar,
    Header,
    HeroBg,
    Footer
  },
  data() {
    return {
      base_url: this.$store.state.base_url,
      lists: [
        'Student admission - add records of student including previous school details, student siblings, multiple documents etc. to maintain complete record in student profile.',
        'Search student with different criteria and keywords. ',
        'Student profiles consist of all student record like admission details, personal, contact, fees, exam, and document details.',
        'Automatically promotes student in next academic session based on pass/fail and continue/leaving school. ',
        'Advanced fees collection mechanism to adopt any type of fees structure including fees type, fees groups, fees masters, free hand fees due date, fine, discount, fees, and discount allotment.',
        'Capture and manage school other incomes and expense head wise with receipt upload.',
        'Manage student attendance smartly in few clicks and monthly attendance report. ',
        'Complete examination management from exam schedule to marks entry to progress report with grades. ',
        'Manage classes, section subjects, and teacher, assign subject and create class timetable. ',
        'Manage downloadable content for students, teacher like syllabus, assignments, study material etc. ',
        'Complete library management system with books adds, members add, books issue/return.',
        'Manage school vehicle/bus/van, routes, and driver details.',
        'Manage school dormitories/hostel, dormitories/hostel rooms with room types. ',
        'Send messages to students, parent and teachers through notice board, SMS and emails. ',
        'Get various reports based on students, fees statement, transaction, balance fees, attendance, and exam.'
      ]
    }
  }
}
</script>

<style scoped>
.app-text {
  text-align: center;
  font: normal normal bold 20px/30px Roboto;
  color: #0864b1;
  text-transform: uppercase;
  opacity: 1;
}
i {
  color: #0864b1;
}
.list {
  color: #000000;
  font: normal normal normal 1em Rubik;
}
.col-1 {
  width: 3% !important;
}
.ready {
  text-align: center;
  font: normal normal normal 2em/32px Rubik;
  letter-spacing: 1.63px;
  color: #17479d;
  text-transform: uppercase;
  opacity: 1;
}
.btn-ready {
  background: #17479d 0% 0% no-repeat padding-box;
  border-radius: 42px;
  text-align: center;
  font: normal normal normal 30px/25px Montserrat;
  letter-spacing: 1.63px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}
</style>
