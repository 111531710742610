<template>
  <section id="topbar" class="d-flex align-items-center">
    <div
      class="container d-flex justify-content-center justify-content-md-between"
    >
      <div class="contact-info d-flex align-items-center">
        <span class="d-none d-md-flex"> For more info</span>
        <i
          v-for="phone in phones"
          :key="phone"
          class="bi bi-telephone-fill d-flex align-items-center ms-4"
          ><span>{{ phone }}</span></i
        >
        <i
          v-for="email in emails"
          :key="email"
          class="bi bi-envelope d-flex align-items-center ms-4"
          ><a :href="'mailto:' + email">{{ email }}</a></i
        >
      </div>
      <div class="social-links d-none d-md-flex align-items-center">
        <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
        <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
        <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
        <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TopBar',
  data() {
    return {
      phones: ['+254 112 899 208', '+254 733 333 877'],
      emails: ['info@legitimate-tech.com']
    }
  }
}
</script>

<style scoped></style>
