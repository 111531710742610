<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />
  <main id="main">
    <solution-header
      name="BETTING SYSTEM"
      client="WaziBet"
      type="Web and Mobile"
    />
  </main>
  <div class="container">
    <div class="row step my-5">
      <div class="col-md-5">
        <img
          :src="`${publicPath}assets/img/portfolio/wazi-bet.jpg`"
          class="img-fluid"
        />
      </div>
      <div class="col-md-7">
        <p class="none-list mt-lg-2">
          WaziBet is an all-round betting system with the following features and
          functionalities:
        </p>
        <div
          v-for="list in lists.slice(0, 9)"
          :key="list"
          class="row py-2 mx-lg-3"
        >
          <div class="col-1">
            <i class="bi bi-check-circle-fill"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
        <div
          v-for="list in lists.slice(10)"
          :key="list"
          class="row py-2 mx-lg-3"
        >
          <div class="col-1">
            <i class="bi bi-check-circle-fill"></i>
          </div>
          <div class="col-11 list">
            {{ list }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
import TopBar from "./TopBar";
import Header from "./Header";
import Footer from "./Footer";
import SolutionHeader from "./SolutionHeader";
export default {
  name: 'betting',
  components: {
    TopBar,
    Header,
    Footer,
    SolutionHeader
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      lists: [
        'Allows customers to view games. ',
        'Place, update and cancel bets. ',
        'Handle bet resulting ',
        'Carry out betting settlement. ',
        'Search upcoming games. ',
        'Play virtual games. ',
        'View game and bet result scores.',
        'Manages customers profiles. ',
        'Allow customers to deposit money for betting through MPESA - lipa na MPESA or STK push and withdraw to their MPESA.',
        'Place a live bet on a live match. ',
        'Create and manage different promotions and bonuses. ',
        'Send automatic messages to customers when they place, update, and cancel bets and when they win. ',
        'Send bulk messages to customers about upcoming games. ',
        'Backend to manage all components of the system and give detailed reports including financial reports, new user registrations.'
      ]
    }
  }
}
</script>

<style scoped>
body {
  background-color: #ffffff !important;
}
i {
  color: #00a7ea;
}
.list {
  color: #000000;
  font: normal normal normal 1em Rubik;
}
.none-list {
  font: normal normal normal 1em/45px Rubik;
  color: #000000;
  opacity: 1;
}
.col-1 {
  width: 3% !important;
}
</style>
