<template>
  <div>
    <!-- ======= Top Bar ======= -->
    <TopBar />

    <!-- ======= Header ======= -->
    <Header />

    <!-- ======= Hero Section ======= -->
    <HeroSection />

    <main id="main">
      <!-- Services Section -->
      <Services />
      <!--- ======= GetQuote ======= -->
      <GetQuote />
      <!-- Solutions Section -->
      <Solutions />
      <!-- ======= Our Partners ======= -->
      <Partners />
      <!-- ======= Testimonials ======= -->
      <Testimonials />
      <!-- ======= Contact Us ======= -->
      <ContactUs />
    </main>
    <Footer />
  </div>
</template>

<script>
  import TopBar from "./TopBar";
  import Header from "./Header";
  import HeroSection from "./HeroSection";
  import Services from "./Services";
  import GetQuote from "./GetQuote";
  import Solutions from "./Solutions";
  import Partners from "./Partners";
  import Testimonials from "./Testimonials";
  import Footer from "./Footer";


export default {
  name: 'Home',
  components: {
    TopBar,
    Header,
    HeroSection,
    Services,
    GetQuote,
    Solutions,
    Partners,
    Testimonials,
    Footer
  }
}
</script>