<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p class="soln-text">
            {{ content }}
          </p>
        </div>
        <div class="col-lg-5">
          <form-quote />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FormQuote from "./FormQuote";
export default {
  name: 'SolutionContent',
  components: {
    FormQuote
  },
  props: {
    content: String
  },
  data() {
    return {
      base_url: this.$store.state.base_url,
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style scoped></style>
