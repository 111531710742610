<template>
  <section
    class="hero-section"
    id="hero1"
    :style="{ backgroundImage: 'url(../assets/img/services/' + image + ')' }"
  >
    <!--
    <div class="wave">
      <img :src="base_url + 'assets/img/services/' + image" alt="alt" />
    </div>
    -->
    <div class="container">
      <div class="row mt-sm-2">
        <div class="col-12 hero-text-image bg-text-mt">
          <div class="row">
            <div class="col-lg-8 text-lg-start mt-sm-2">
              <h1 data-aos="fade-right" class="hero-title">
                {{ title }}
              </h1>
              <div class="row">
                <div class="col-6 header-box">
                  <h3
                    class="mb-4 text-white hero-text"
                    data-aos="fade-right"
                    data-aos-delay="100"
                  >
                    {{ content }}
                  </h3>
                  <router-link to="/solutions/get-solution">
                    <div class="btn btn-round">
                      {{ button }}
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
            <!--
            <div class="col-lg-4 iphone-wrap">
              <img src="assets/img/phone_1.png" alt="Image" class="phone-1" data-aos="fade-right">
              <img src="assets/img/phone_2.png" alt="Image" class="phone-2" data-aos="fade-right" data-aos-delay="200">
            </div>
            -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'HeroBg',
  props: {
    title: String,
    content: String,
    url: String,
    image: String,
    button: String
  },
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>
