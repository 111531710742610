<template>
  <div>
  <!-- ======= Top Bar ======= -->
  <top-bar />

  <!-- ======= Header ======= -->
  <Header color error />

  <!-- ======= Hero Section ======= -->
  <hero-bg
    title="CUSTOM ERPS"
    content="Our ERP software’s fully integrate all modules for managing workforce, workflows, financial flows, and automation of other processes."
    url="#"
    image="erp.jpg"
    button="GET SERVICE"
  />

  <main id="main">
    <solution-content
      content="We develop customized ERP systems to help businesses and organizations bring all their operations into one single, simple, and easy to use software solution. Our ERP software’s fully integrate all modules for managing workforce, workflows, financial flows, and automation of other processes. ERP help you to keep all your data in one single location, making it consistent and up to date and provides comprehensive reports."
    />
  </main>

  <!-- footer -->
  <Footer />
  </div>
</template>

<script>
import TopBar from "./TopBar";
import Header from "./Header";
import HeroBg from "./HeroBg";
import SolutionContent from "./SolutionContent";

import Footer from "./Footer";
export default {
  name: 'custom-erp-dev',
  components: {
    TopBar,
    Header,
    HeroBg,
    SolutionContent,
    Footer
  }
}
</script>

<style scoped></style>
