<template>
  <section id="hero" class="d-flex align-items-center">
    <div class="container" data-aos="zoom-out" data-aos-delay="100">
      <h2 class="text-center text-white">HAVE A PROJECT?</h2>
      <p class="text-center text-white">
        Do you have a Software / Technology Project? Reach out to us for a free
        quote.
      </p>
      <div class="text-center">
        <p data-aos="fade-right" data-aos-delay="200" data-aos-offset="-500">
          <router-link :to="{ name: 'get-soln' }">
            <a href="#" class="btn btn-get-quote"
              >GET FREE QUOTE<span class="get-it-icon"
                ><i class="bi bi-arrow-right"></i></span
            ></a>
          </router-link>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'GetQuote'
}
</script>

<style scoped>
a:hover {
  color: #ffffff;
}
</style>
