<template>
  <section id="testimonials pt-0" class="section">
    <div class="container">
      <div class="row">
        <div v-for="item in items" :key="item.profile" class="col-md-4 mt-0">
          <div class="testimonial-1 rounded step">
            <div class="text-right">
              <img
                class="mb-3"
                :src="`${publicPath}assets/img/quote-right.svg`"
                width="40"
                alt="alt"
              />
            </div>
            <p class="p-testimonial text-justify">
              {{ item.testimony }}
            </p>
            <img
              class="mb-3"
              :src="`${publicPath}assets/img/quote-left.svg`"
              width="40"
              alt="alt"
            />
            <div class="text-center">
              <div class="m-lg-3 about">
                <span class="t-title">- {{ item.title }}</span>
                <span class="d-block t-name">{{ item.user }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Testimonials',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      items: [
        {
          profile: 'https://i.imgur.com/sKDeBrg.jpg',
          testimony:
            'I am really impressed by the quality of services I received from SMEs Tech Innovations. You within schedule and budget and were very professional. I now have got a good e-commerce site for my products.',
          user: 'CALVINCE OKELLO',
          title: 'M-Shamba'
        },
        {
          profile: 'https://i.imgur.com/sKDeBrg.jpg',
          testimony:
            'We thank SMEs Tech for the wonderful job in helping us develop our program. Everyone was professional, excellent, and hard working. Thanks to them, we were able to achieve our goal on time, and we look forward to continuing working with them in the future.',
          user: 'PETER KIMEU',
          title: 'Focus Kenya'
        },
        {
          profile: 'https://i.imgur.com/sKDeBrg.jpg',
          testimony:
            'We are very happy with SMEs Tech, very serious and consistent in their work. They have always been there for us all the time. This is a company I can recommend to anyone to perform any work.',
          user: 'RONALD KIMANI',
          title: 'Sportika'
        }
      ]
    }
  }
}
</script>

<style scoped>
.text-justify {
  text-align: justify;
}
.step {
  /*min-height: 33em;*/
}
</style>
