import Home from './components/Home'
import WebAppDev from "./components/WebAppDev";
import Portfolio from "./components/Portfolio";
import WebDesignDev from "./components/WebDesignDev";
import MobileAppDev from "./components/MobileAppDev";
import UssdSmsDev from "./components/UssdSmsDev";
import CustomErpDev from "./components/CustomErpDev";
import UiDesignDev from "./components/UiDesignDev";
import SchoolSystem from "./components/SchoolSystem";
import ProductPromotion from "./components/ProductPromotion";
import VasSystem from "./components/VasSystem";
import Bonga from "./components/Bonga";
import CropInformatics from "./components/CropInformatics";
import Fellowship from "./components/Fellowship";
import Ecommerce from "./components/Ecommerce";
import Betting from "./components/Betting";
import StoriezApp from "./components/StoriezApp";
import AgmSystem from "./components/AgmSystem";
import Sqwodi from "./components/Sqwodi";
import KisumuMapApp from "./components/KisumuMapApp";
import Abudu from "./components/Abudu";

export const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/web-application-development',
        name: 'web-app-dev',
        component: WebAppDev
    },
    {
        path: '/web-design-development',
        name: 'web-design-dev',
        component: WebDesignDev
    },
    {
        path: '/mobile-app-development',
        name: 'mobile-app-dev',
        component: MobileAppDev
    },
    {
        path: '/ussd-and-sms-development',
        name: 'ussd-sms-dev',
        component: UssdSmsDev
    },
    {
        path: '/custom-erp-development',
        name: 'custom-erp-dev',
        component: CustomErpDev
    },
    {
        path: '/ui-ux-design-development',
        name: 'ui-design-dev',
        component: UiDesignDev
    },
    {
        path: '/school-management-system',
        name: 'school-system',
        component: SchoolSystem
    },
    {
        path: '/product-promotion-system',
        name: 'product-promotion',
        component: ProductPromotion
    },
    {
        path: '/vas-system',
        name: 'vas-system',
        component: VasSystem
    },
    {
        path: '/bonga-for-cash',
        name: 'bonga',
        component: Bonga
    },
    {
        path: '/portfolio',
        name: 'Portfolio',
        component: Portfolio
    },
    {
        path: '/crop-informatics-system',
        name: 'crop-informatics',
        component: CropInformatics
    },
    {
        path: '/fellowship-management-system',
        name: 'fellowship',
        component: Fellowship
    },
    {
        path: '/e-commerce-system',
        name: 'e-commerce',
        component: Ecommerce
    },
    {
        path: '/betting-system',
        name: 'betting',
        component: Betting
    },
    {
        path: '/storiez-app',
        name: 'storiez-app',
        component: StoriezApp
    },
    {
        path: '/virtual-agm-management-system',
        name: 'agm-system',
        component: AgmSystem
    },
    {
        path: '/kisumu-county-mapping-app',
        name: 'kisumu-map-app',
        component: KisumuMapApp
    },
    {
        path: '/abudu',
        name: 'abudu',
        component: Abudu
    },
    {
        path: '/sqwodi',
        name: 'sqwodi',
        component: Sqwodi
    },
];